import fetchApi from "@/utils/fetchApi";
import * as restAPI from "@/utils/endpoints";

export const fetchApprovalsDataForBenefit = async (benefitId, setApprovalsData, setView = false) =>
{
	const employeeData = await fetchApi(restAPI.GET_SELECTIONS_FOR_APPROVAL_FOR_BENEFIT(benefitId))

	if(setView) {
		setView(false)
  	}

	setApprovalsData(employeeData)
}
