import * as React from 'react'
import className from 'classnames'
import { node, bool, func } from 'prop-types'
import Modal from '@/components/widgets/Modal'
import CloseIcon from '@/icons/icon-close-xs.svg'
import IconWrapper from '@/utils/IconWrapper'

function SideBar({ children, isOpen, closeSideBar }) {
	return (
		<Modal
			label="test"
			overlayClassName={className(
				'perf-fixed w-full z-100 inset-0 flex justify-end sidebar'
			)}
			closeTimeoutMS={300}
			className="w-full h-full max-w-slide"
		>
			<div
				className={className(
					'transition-transform will-change-transform  pl-md md:pl-2xl',
					{
						'x-100': !isOpen,
						'x-0 transition-delay-500': isOpen
					}
				)}
			>
				<div className="relative w-full shadow">
					<div className="h-screen overflow-auto bg-white shadow h-page">
						<button
							type="button"
							className="absolute flex items-center top-md right-md"
							onClick={closeSideBar}
						>
							<IconWrapper
								icon={CloseIcon}
								wrapperClassName="w-md text-primary"
							/>
						</button>
						<div className="w-full min-h-screen bg-white shadow">
							{children}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

SideBar.propTypes = {
	children: node.isRequired,
	isOpen: bool,
	closeSideBar: func.isRequired
}

export default SideBar
