import * as React from 'react'
import classNames from 'classnames'
import { node, string, bool } from 'prop-types'

function FormFooter({ children, className, breakout = false }) {
	return (
		<div
			className={classNames(
				'shadow-footer z-50 py-md px-xl flex justify-end items-center bottom-0 inset-x-0 bg-white inset-x',
				className,
				{ absolute: !className, 'absolute break-out': breakout }
			)}
		>
			{children}
		</div>
	)
}

FormFooter.propTypes = {
	children: node.isRequired,
	className: string,
	breakout: bool
}

export default FormFooter
