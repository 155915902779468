import * as React from 'react'
import { node } from 'prop-types'
import PageError from '@/components/screens/PageError'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: !!error }
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		// eslint-disable-next-line no-console
		console.error('componentDidCatch:', { error, info, props: this.props })
	}

	render() {
		const { hasError } = this.state
		const { children } = this.props
		if (hasError) {
			// You can render any custom fallback UI
			return (
				<PageError>
					<h1 className="text-xl mb-lg">Uh oh! </h1>
					<p>
						It looks like something has gone wrong. Please try refreshing the
						page or click below to return to the home page
					</p>
				</PageError>
			)
		}

		return children
	}
}

ErrorBoundary.propTypes = {
	children: node.isRequired
}

export default ErrorBoundary
