import * as React from 'react'
import ReactModal from 'react-modal'
import { node, func, string, number } from 'prop-types'
import { SideBarContext } from '@/container/SideBarProvider'

ReactModal.setAppElement('body')

function Modal({
	label,
	children,
	onAfterOpen,
	onRequestClose,
	className = 'outline-none',
	closeTimeoutMS = 300,
	overlayClassName = 'perf-fixed z-100 inset-0 w-full flex items-center justify-center'
}) {
	const { isOpen, closeSideBar } = React.useContext(SideBarContext)

	return (
		<ReactModal
			onAfterOpen={onAfterOpen}
			isOpen={isOpen}
			closeTimeoutMS={closeTimeoutMS}
			onRequestClose={() => {
				if (onRequestClose) onRequestClose()
				closeSideBar()
			}}
			contentLabel={label}
			className={className}
			overlayClassName={overlayClassName}
		>
			{children}
		</ReactModal>
	)
}

Modal.propTypes = {
	children: node.isRequired,
	label: string.isRequired,
	onAfterOpen: func,
	onRequestClose: func,
	className: string,
	overlayClassName: string,
	closeTimeoutMS: number
}

export default Modal
