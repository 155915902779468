import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import withAuth from '@/utils/withAuth';

import {generateAPIURL} from "@/utils/fetchApi";
import classNames from "classnames";
import useProgressBar from "@/hooks/useProgressBar";

function EmployerCCVManage() {
	const ref = React.useRef();
	const { isLoading, setLoading } = useProgressBar(true)

	return (
		<div ref={ref} className="relative form-screen pb-2xl">
			<Helmet>
				<title>{`${PROJECT_NAME}`}</title>
			</Helmet>
			<div className="relative mb-2xl pt-lg">
				<iframe
					onLoad={() => {
						setLoading(false)
					}}
					src={generateAPIURL(SITE_URL, '/manage-ccv')}
					title={`${PROJECT_NAME}`}
					className={classNames('w-full', { 'absolute top-0 left-0': isLoading })}
					style={{ minHeight: '90vh' }}
					data-testid="employer-ccv-iframe"
				/>
			</div>
		</div>
	)
}

export default withAuth(EmployerCCVManage)
