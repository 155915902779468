import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import classNames from 'classnames'
import Notification from '@/components/user/Notification'
import Button from '@/components/buttons/Button'
import Checkbox from '@/components/formFields/Checkbox'
import RichText from '@/utils/RichText'
import Prompt from '@/utils/Prompt'
import FormFooter from '@/utils/FormFooter'
import Text from '@/utils/Text'
// import InputError from '@/utils/InputError'
import Loading from '@/components/screens/Loading'
import Layout from '@/container/Layout'
import useFocusOnFirstInput from '@/hooks/useFocusOnFirstInput'
import useFormState from '@/hooks/useFormState'
import useProgressBar from '@/hooks/useProgressBar'
import * as m from '@/utils/messages'

const LOADING = 'LOADING'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

function NewTerms() {
	const [view, setView] = React.useState(LOADING)
	const { terms, policy, status } = useSelector(state => state.content)
	const dispatch = useDispatch()
	const { setLoading } = useProgressBar()
	const { termsRevision } = useSelector(state => state.user)

	const SHOW_LOADING = view === LOADING
	const SHOW_SUCCESS = view === SUCCESS
	const SHOW_ERROR = view === ERROR

	const [submitError, setSubmitError] = React.useState()
	const formRef = React.useRef()

	const { register, handleSubmit, errors, formState } = useForm({
		mode: 'onBlur'
	})

	const onSubmit = async data => {
		await dispatch({
			type: 'user/PATCH_TERMS',
			payload: data.termsAccepted
		})
	}

	useFormState(formState.isSubmitting)
	useFocusOnFirstInput(formRef)

	const hasFieldErrors = !!Object.keys(errors).length

	React.useEffect(() => {
		const fetchTerms = async () => {
			await dispatch({ type: 'content/REQUEST_ALL_CONTENT' })
			setLoading(false)
		}

		if (status === false) {
			setLoading(true)
			fetchTerms()
		}
	}, [status, setLoading, dispatch])

	React.useEffect(() => {
		if (status === 'success') {
			setView(SUCCESS)
		} else if (status === 'error') {
			setView(ERROR)
		}
	}, [status])

	// only show the submit errors if a submission has been
	// attempted, and the submission set the loginError
	// and, only if the form is not currently being submitted
	const showFormErrors =
		formState.submitCount > 0 &&
		(hasFieldErrors || submitError) &&
		!formState.isSubmitting

	React.useEffect(() => {
		if (!termsRevision && !!formState.submitCount) {
			setSubmitError(m.CONFIRM_READ_TERMS)
			// if there is an error, scroll to the top of the world
			// react-hook-form will scroll to the first input element
			// with an error... however, the input is at the bottom
			// of the screen, so we should scroll to the top of the
			// screen so that the user can see the error message
			window.scrollTo(0, 0)
		}
	}, [termsRevision, formState.submitCount])

	return (
		<Layout>
			{SHOW_LOADING && <Loading>{m.LOADING_PROFILE}</Loading>}
			{SHOW_SUCCESS && (
				<div className="py-lg">
					<form
						className="max-w-2xl mx-auto"
						ref={formRef}
						method="post"
						data-testid="new-terms-form"
						noValidate
						onSubmit={handleSubmit(onSubmit)}
					>
						<Prompt show />

						{showFormErrors && (
							<Notification
								level="error"
								data-testid="terms-form-error"
								className="whitespace-pre-wrap mb-lg"
							>
								{submitError}
							</Notification>
						)}

						<Text className="text-center mb-xl">Nearly there</Text>

						<div className="relative z-10 rounded-10 mb-3xl">
							<div
								className={classNames(
									'bg-white z-20 relative rounded-10 border-2 p-md max-h-md md:max-h-md overflow-auto mb-xl',
									{
										'border-s-error-50': errors?.termsAccepted?.message
									},
									{
										'border-primary-60': !errors?.termsAccepted?.message
									}
								)}
							>
								<Text as="h1" className="text-mob-xl lg:text-xl mb-xl">
									Terms and conditions
								</Text>
								{terms && (
									<div className="mb-xl">
										<RichText dangerouslySetInnerHTML={{ __html: terms }} />
									</div>
								)}
								<h1 className="text-mob-xl lg:text-xl mb-xl">Policy</h1>
								{policy && (
									<div className="mb-xl">
										<RichText dangerouslySetInnerHTML={{ __html: policy }} />
									</div>
								)}
							</div>
						</div>
						<FormFooter className="absolute justify-between">
							<Checkbox
								name="termsAccepted"
								id="termsAccepted"
								data-testid="termsAccepted"
								ref={register({
									required: {
										value: true,
										message: m.REQUIRED_FIELD
									}
								})}
								error={errors?.termsAccepted?.message}
								className="flex-grow"
							>
								I have read the terms and conditions
							</Checkbox>
							<Button
								className="flex-2 ml-lg"
								data-testid="submit"
								large
								as="button"
								type="submit"
								disabled={formState.isSubmitting}
							>
								Next
							</Button>
						</FormFooter>
					</form>
				</div>
			)}
			{SHOW_ERROR && (
				<div data-testid="page-error" className="flex items-center form-screen">
					<div className="mx-auto max-w-login py-xl">
						<div className="mb-lg lg:mb-xl">
							<h1 className="text-xl mb-xl">Uh oh!</h1>
							<p>{m.SOMETHING_HAS_GONE_WRONG}</p>
						</div>
					</div>
				</div>
			)}
		</Layout>
	)
}
export default NewTerms
