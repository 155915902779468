import ReactModal from "react-modal";
import Text from "@/utils/Text";
import Button from "@/components/buttons/Button";
import * as React from "react";
import { capitaliseFirstLetter, selectionIdsToAffect, submit } from "@/utils/ViewApprovalsData/approvalsFormHelpers";
import { useDispatch } from "react-redux";

const ApprovalsModal = ({ formData, setShowModal, approvalType }) =>
{
	const dispatch = useDispatch()

	return (
		<ReactModal
			isOpen
			closeTimeoutMS={300}
			className="outline-none"
			overlayClassName="perf-fixed z-100 inset-0 w-full flex items-center justify-center"
		>
			<div className="relative md:w-600 mx-auto overflow-hidden bg-white shadow rounded-20 md:rounded-30 lg:round p-lg">
				<div className="relative z-10">
					{selectionIdsToAffect(formData).length ? (
						<Text as='p'>Are you sure you would like to {approvalType} these selections?</Text>
					) : (
						<Text as='p'>No selections to {approvalType}, please review your choice</Text>
					)}
					<div className="md:flex justify-between mt-xl">
						<div className="mb-sm md:mb-0 md:mr-xl">
							<Button
								theme="secondary"
								type='button'
								onClick={() => setShowModal(false)}
								className='flex items-center top-sm right-sm border border-primary-20'
							>
								{selectionIdsToAffect(formData).length ? 'Cancel' : 'Close' }
							</Button>
						</div>
						{(selectionIdsToAffect(formData).length >= 1) && (
							<div>
								<Button
									onClick={() => submit(formData, approvalType, dispatch)}
									className='flex items-center top-sm right-sm '
								>
									{capitaliseFirstLetter(approvalType)}
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</ReactModal>
	)
}

export default ApprovalsModal
