import { Children, createElement } from 'react'
import { node, oneOfType, string, number, bool } from 'prop-types'

function Switch({ children, value }) {
	return Children.map(children, child => {
		const { case: type, ...rest } = child.props
		const result = typeof type === 'function' ? type(value) : value === type
		return result ? createElement(child.type, rest) : null
	})
}

Switch.propTypes = {
	children: node.isRequired,
	value: oneOfType([string, number, bool]).isRequired // eslint-disable-line react/forbid-prop-types
}

export default Switch
