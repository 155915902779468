import { useEffect } from 'react'
import { bool } from 'prop-types'

function Prompt({ show = false, callback }) {
	useEffect(() => {
		function handle(e) {
			if (typeof callback === 'function') callback()
			e.returnValue = ''
		}

		if (typeof window !== 'undefined' && show) {
			window.addEventListener('beforeunload', handle)
		}

		return () => {
			if (typeof window !== 'undefined' && show) {
				window.removeEventListener('beforeunload', handle)
			}
		}
	}, [callback, show])

	return null
}

Prompt.propTypes = {
	show: bool
}

export default Prompt
