import * as React from 'react'
import Link from '@/utils/Link'
import { node } from 'prop-types'
import Layout from '@/container/Layout'
import Button from '@/components/buttons/Button'
// import Text from '@/utils/Text'
// import Center from '@/utils/Center'

function PageError({ children }) {
	return (
		<Layout>
			<div data-testid="page-error" className="flex items-center form-screen">
				<div className="mx-auto max-w-login py-xl">
					<div className="mb-lg lg:mb-xl">{children}</div>
					<Button
						as={Link}
						to="/"
						className="flex-grow w-full mr-md px-xl md:w-auto md:px-2xl"
					>
						Return to home
					</Button>
				</div>
			</div>
		</Layout>
	)
}

PageError.propTypes = {
	children: node.isRequired
}

export default PageError
