import * as React from 'react'
import { string, node, oneOfType, bool, object } from 'prop-types'
import classNames from 'classnames'
import Text from '@/utils/Text'
import Center from '@/utils/Center'
import IconWrapper from '@/utils/IconWrapper'
import Tick from '@/icons/icon-terms-tick.svg'
import styles from './Checkbox.module.css'

const Checkbox = React.forwardRef(
	({ children, name, id, className, error = false, style, ...rest }, ref) => {
		return (
			<label
				htmlFor={id}
				className={classNames('flex items-baseline cursor-pointer', className)}
				data-testid="label"
				style={style}
			>
				<input
					className={classNames(styles.input, 'input-focus:border-primary-80')}
					type="checkbox"
					id={id}
					name={name}
					ref={ref}
					{...rest}
				/>
				<Center
					className={classNames(
						'h-lg min-w-lg mr-md border-2',
						{ 'border-primary-60': !error },
						{ 'border-s-error-50': error },
						styles.checkbox
					)}
				>
					<IconWrapper
						icon={Tick}
						wrapperClassName={classNames('invisible text-primary', styles.tick)}
						width={12}
					/>
				</Center>
				<Text className="flex-1 text-base">{children}</Text>
			</label>
		)
	}
)

Checkbox.propTypes = {
	children: node.isRequired,
	id: string.isRequired,
	name: string.isRequired,
	className: string,
	error: oneOfType([string, bool]),
	// eslint-disable-next-line react/forbid-prop-types
	style: object
}

export default React.memo(Checkbox)
