import * as React from "react"
import Spinner from "@/utils/Spinner";
import Center from "@/utils/Center";
import Text from "@/utils/Text";
import { fetchApprovalsDataForBenefit } from "@/utils/ViewApprovalsData";
import { GenerateApprovalsDataTable } from "@/utils/ViewApprovalsData/GenerateApprovalsDataTable";

const ViewApprovalsData = props =>
{
	const [loading, setLoading] = React.useState(true)
	const [ approvalsData, setApprovalsData ] = React.useState(false)

	const {
		data: {
			benefitId
		},
		closeSideBar
	} = props

	if(!approvalsData) {
		fetchApprovalsDataForBenefit(benefitId, setApprovalsData, setLoading)
	}

	return (
		<>
			{loading && (
				<Center className={`flex flex-col w-full h-150`}>
					<Spinner className="mb-xl" style={{ width: 80, height: 80 }} />
				</Center>
			)}

			<div className="flex flex-row-reverse mt-lg">			{approvalsData && !loading && (
				<GenerateApprovalsDataTable approvalsData={approvalsData} closeSideBar={closeSideBar} />
			)}
				<Text
					as="button"
					type="button"
					inline
					onClick={closeSideBar}
					className="w-full text-center text-primary mr-lg md:w-auto"
				>
					Close
				</Text>
			</div>
		</>
	)
}

export default ViewApprovalsData
