import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import Login from '@/templates/Login'
import NewTerms from '@/templates/NewTerms'
import Loading from '@/components/screens/Loading'
import * as R from "ramda";
import * as m from '@/utils/messages';
import useCCVOnly from "../hooks/useCCVOnly";

const LOADING = 'LOADING'
const NEW_TERMS = 'NEW_TERMS'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

export default function withAuth(Component, role) {
	const AuthWrapper = props => {
		const [view, setView] = React.useState(LOADING)

		const SHOW_LOADING = view === LOADING
		const SHOW_NEW_TERMS = view === NEW_TERMS
		const SHOW_SUCCESS = view === SUCCESS
		const SHOW_ERROR = view === ERROR

		const { loggedIn, loginError, siteTermsUpdated, userType } = useSelector(
			state => state.user
		)

		useCCVOnly()

		React.useEffect(() => {
			if (siteTermsUpdated) {
				setView(NEW_TERMS)
				return
			}

				if (role && role !== userType) {
					setView(ERROR)
					return
				}

			if (loggedIn) {
				setView(SUCCESS)
			} else if(!loggedIn && loginError) {
				navigate('/');
			}

		}, [siteTermsUpdated, loggedIn, userType, SHOW_LOADING])

		return React.useMemo(() => {
			switch (true) {
				case SHOW_LOADING:
					return <Loading>Loading profile</Loading>
				case SHOW_NEW_TERMS:
					return <NewTerms />
				case SHOW_SUCCESS:
					return <Component {...props} />
				case SHOW_ERROR:
					return <div>access denied</div>
				default:
					return <Login />
			}
		}, [SHOW_LOADING, SHOW_NEW_TERMS, SHOW_SUCCESS, SHOW_ERROR, props])
	}



	return AuthWrapper
}
